import React from 'react';
import { graphql } from 'gatsby';
import { Flex, Box, Text, Heading, Image, Divider } from '@chakra-ui/core';
import Helmet from 'react-helmet';

import { colors } from '@theme';
import Layout from '@components/layout';
import ContentContainer from '@components/ContentContainer';
import Diversity from '@components/careers/Diversity';
import Benefits from '@components/careers/Benefits';
import JobListings from '@components/careers/JobListings';
import { TextLink } from '@zeta';
import { DesktopOnly, MobileOnly } from '../layouts/Footer';

const Careers = props => {
  const jobPostings = props.data.allContentfulJobPosting.edges || [];

  return (
    <>
      <Helmet>
        <title>Join our team: Explore our mission-driven roles at Zeta</title>
        <link rel="canonical" href="https://www.askzeta.com/careers" />
        <meta
          name="description"
          content="Explore a mission-driven career at Zeta. Help us build a financial institution focused on helping families, of all shapes and sizes, thrive."
        />
        <meta
          name="og:description"
          content="Explore a mission-driven career at Zeta. Help us build a financial institution focused on helping families, of all shapes and sizes, thrive."
        />
      </Helmet>
      <Layout noRootContainer mode={'home'}>
        <Box pt={['6rem', '6rem']} />
        <Flex className="main" pt={['1rem', '6rem']} direction="column">
          <ContentContainer>
            <Flex
              pl={[4, 0]}
              pr={[6, 0]}
              className="Heading"
              direction="column"
              mb={[4, 20]}
            >
              <Flex direction="column">
                <Text
                  align={['center', 'left']}
                  style={{
                    color: colors.primary,
                    fontSize: ['20px', '18px'],
                    fontWeight: [700, 600],
                  }}
                  as="h1"
                >
                  WORK AT ZETA
                </Text>
                <DesktopOnly>
                  <Heading
                    pt="1rem"
                    width={['10rem', '52rem']}
                    fontSize={['24px', '40px']}
                  >
                    We're building a financial institution focused on helping families
                    thrive.
                  </Heading>{' '}
                </DesktopOnly>
                <MobileOnly>
                  <Text
                    fontWeight={700}
                    fontSize={['24px', '40px']}
                    mt={[6]}
                    lineHeight={1.25}
                  >
                    We're building a financial institution focused on helping families
                    thrive.
                  </Text>
                </MobileOnly>

                <Box pt={2}>
                  <TextLink
                    to={'/careers/#jobs'}
                    text="See Open Roles"
                    variant="primary"
                    noNewTab
                  />
                </Box>
              </Flex>
            </Flex>
          </ContentContainer>

          <Flex
            className="join-zeta"
            fontSize={['18px', '24px']}
            direction="column"
            bg={colors.yellowLight}
          >
            <ContentContainer>
              <Flex py={['3rem', '6rem']} direction="column">
                <Text pl={[4, 0]} pr={[6, 0]} fontSize={['18px', '24px']}>
                  Hi there,
                  <br />
                  <br />
                  Glad you'd like to be a part of Zeta!
                  <br />
                  <br />
                  We believe money shouldn't be the reason families break up. Nor should
                  it be that hard to master. Our mission is to use technology to build a
                  better future for couples so we can all live the lives we've dreamed of.
                  <br />
                  <br />
                  Our team is a group of passionate, quirky, fun and determined people who
                  take our emojis seriously and our coffee strong. If you're as excited
                  about love and money as we are, we'd be pumped to meet you.
                  <br />
                  <br />
                  <span style={{ fontStyle: 'italic' }}>Team Zeta</span>
                  <br />
                  <br />
                  PS - you can check out our team{' '}
                  <a style={{ color: colors.greenPea }} href="/aboutus">
                    here
                  </a>
                  .
                </Text>
              </Flex>
            </ContentContainer>
          </Flex>

          <Benefits />
          <JobListings jobPostings={jobPostings} />
          <Diversity />
        </Flex>
      </Layout>
    </>
  );
};

const styles = {
  link: {
    color: colors.greenPea,
    textDecoration: 'underline',
  },
  heading: {
    fontSize: ['28px', '40px'],
  },
  bold: {
    fontWeight: 600,
  },
  text: {
    fontSize: ['16px', '18px'],
  },
};

export const pageQuery = graphql`
  query {
    allContentfulJobPosting {
      edges {
        node {
          jobTitle
          positionType
          specificDays
          location
          jdLink
        }
      }
    }
  }
`;

export default Careers;
