import React from 'react';
import { Flex, Box, Text, Heading, Image } from '@chakra-ui/core';
import Helmet from 'react-helmet';

import { colors } from '@theme';
import ContentContainer from '@components/ContentContainer';
import { benefits } from '@components/careers/consts';

const Benefits = () => {
  return (
    <Flex
      className="benefits"
      fontSize={['18px', '24px']}
      direction="column"
      bg={colors.background}
    >
      <ContentContainer>
        <Flex pl={[4, 0]} pr={[6, 0]} py={['2rem', '6rem']} direction="column">
          <Flex direction="column">
            <Heading fontSize={['28px', '40px']}>Benefits at Zeta</Heading>
            <Text
              fontSize={['16px', '18px']}
              lineHeight="25px"
              maxWidth="52rem"
              pt={['1rem', '1rem']}
              color={colors.primary}
            >
              One of our key metrics as a team is about helping Zeta team members and
              their families thrive. Here are some of ways we try to do that:
            </Text>
          </Flex>
        </Flex>

        <Flex direction={['column', 'row']} wrap={['auto', 'wrap']} pb={['1rem', '3rem']}>
          {benefits.map(benefit => (
            <Box
              key={benefit.title}
              pt={['1rem', '2rem']}
              pb={['1rem', '3rem']}
              px={4}
              width={['100%', '33%', '25%']}
            >
              <Image height="4rem" src={benefit.link} alt={benefit.alt} />
              <Text py="1rem" fontWeight={600} fontSize="18px" color={colors.primary}>
                {benefit.title}
              </Text>
              <Text fontSize="16px">{benefit.body}</Text>
            </Box>
          ))}
        </Flex>
      </ContentContainer>
    </Flex>
  );
};

export default Benefits;
