import { cloudfrontBaseUrl } from '../../lib/consts';

export const benefits = [
  {
    title: 'Equity Owners',
    body: `Our success is thanks to teammates like you. You’ll be an owner in our
    company so you can benefit from that work.`,
    link: `${cloudfrontBaseUrl}/careers/splits.png`,
    alt: 'pie chart icon',
  },
  {
    title: 'Flexible PTO',
    body: `Six week PTO. Vacation the way you want with our flexible vacation and PTO policy.`,
    link: `${cloudfrontBaseUrl}/careers/crane.png`,
    alt: 'green paper crane icon',
  },
  {
    title: 'Healthcare',
    body: `We’ll cover your health insurance and give you access to dental, vision and
    life insurance.`,
    link: `${cloudfrontBaseUrl}/careers/healthcare.png`,
    alt: 'person icon with a small plus to represent healthcare',
  },
  {
    title: '401K Contributions',
    body: `In addition to offering a 401k, Zeta contributes 5% of your salary to your
    retirement account (no personal contribution required).`,
    link: `${cloudfrontBaseUrl}/careers/401k2.png`,
    alt: 'three small circles with dollar signs to indicate money icon',
  },
  {
    title: 'Fully Remote',
    body: `Work from anywhere with an internet connection. We’ve built a culture of
    flexibility and trust.`,
    link: `${cloudfrontBaseUrl}/careers/remote.png`,
    alt: 'tablet computer showing a picture of a bar graph',
  },
  {
    title: 'Family Leave',
    body: `Get up to 8 weeks of paid family leave for all birthing and non-birthing
    parents.`,
    link: `${cloudfrontBaseUrl}/careers/family.png`,
    alt: 'icon consistening of four small circles representing a family',
  },
];
