import React from 'react';
import { Flex, Box, Text, Heading } from '@chakra-ui/core';

import { colors } from '@theme';
import ContentContainer from '@components/ContentContainer';
import { TextLink } from '@zeta';

const Diversity = () => {
  return (
    <Flex direction="column" bg={colors.greenPea} className="diversity">
      <ContentContainer>
        <Flex pt="4rem">
          <Heading color={colors.white} ml={[4, 0]} fontSize={['28px', '40px']}>
            Our Commitment to Diversity
          </Heading>
        </Flex>

        <Flex direction="column" ml={[4, 0]} pt="1.5rem" pb="3rem">
          <Text color={colors.white} fontSize="18px">
            We’re such big believers in diversity, we wrote a whole manifesto on it! We
            believe in diversity across every layer of our organization. And we hold
            ourselves accountable to the goals we’ve set for ourselves.
          </Text>
          <Box pt="1rem">
            <TextLink
              text="Read our Diversity Manifesto"
              variant="secondary"
              noNewTab
              to="/diversity"
            />
          </Box>
        </Flex>
      </ContentContainer>
    </Flex>
  );
};

export default Diversity;
