import React, { useState } from 'react';
import { Flex, Text, Heading, Box, Link } from '@chakra-ui/core';

import { colors } from '@theme';
import ContentContainer from '@components/ContentContainer';
import RightChevron from '@lib/rightChevron';

const JobListings = ({ jobPostings }) => {
  return (
    <>
      <Flex
        id="jobs"
        className="job-listings"
        fontSize={['18px', '24px']}
        direction="column"
        bg={colors.white}
        pl={[4, 0]}
        pr={[6, 0]}
      >
        <ContentContainer>
          <Flex pt={['2rem', '6rem']} pb={['2rem', '2.5rem']} direction="column">
            <Flex direction="column">
              <Heading fontSize={['28px', '40px']}>Open Roles</Heading>
              <Text
                fontSize="18px"
                lineHeight="25px"
                maxWidth="52rem"
                pt={['1rem', '1rem']}
                color={colors.primary}
              >
                If you see a role that fits, send us a note at{' '}
                <a style={{ color: colors.greenPea }} href="mailto:careers@askzeta.com">
                  careers@askzeta.com
                </a>
                . If you don’t see something you’re looking for, drop us a note anyway
                &mdash; we believe in serendipity.
              </Text>
            </Flex>
          </Flex>
          <Flex pb={['2rem', '4rem']} direction="column">
            {jobPostings.map((job, idx) => {
              if (job.node.jobTitle.includes('laceholder')) return null;
              return (
                <Box py={'1.5rem'} key={idx}>
                  <ExternalLink text={job.node.jobTitle} to={job.node.jdLink} />
                  <Text fontSize="16px">
                    {job.node.positionType},{' '}
                    {job.node.specificDays ? `${job.node.specificDays}, ` : ''}
                    {job.node.location}
                  </Text>
                </Box>
              );
            })}
          </Flex>
        </ContentContainer>
      </Flex>
    </>
  );
};

const ExternalLink = ({ text, to, variant, ...rest }) => {
  const [chevron, setChevron] = useState(false);

  let secondaryColors = chevron ? colors.shared : colors.white;
  let primaryColors = chevron ? colors.shared : colors.greenPea;

  if (variant == 'primary') {
    style = { ...style };
  } else if (variant == 'secondary') {
    style = {
      ...style,
      color: secondaryColors,
    };
  }

  let style = {
    textDecoration: chevron ? 'underline' : null,
    color: primaryColors,
    align: 'left',
  };

  return (
    <Link isExternal href={to} {...rest}>
      <Flex
        onMouseEnter={() => {
          setChevron(true);
        }}
        onMouseLeave={() => {
          setChevron(false);
        }}
        align="center"
        direction="row"
      >
        <Text
          fontSize="18px"
          mr={-6}
          as="button"
          fontWeight="semibold"
          style={{ ...style }}
        >
          {text}
        </Text>
        <RightChevron
          pl={6}
          height={chevron ? 24 : 22}
          width={chevron ? 36 : 22}
          fill={variant === 'primary' ? primaryColors : secondaryColors}
        />
      </Flex>
    </Link>
  );
};

export default JobListings;
